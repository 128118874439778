import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';

import { GridItemComponent } from './grid-item/grid-item.component';

@NgModule({
	declarations: [
    GridItemComponent
	],
	imports: [
		IonicModule,
		CommonModule
	],
	entryComponents: [
    GridItemComponent
	],
	exports: [
    GridItemComponent
	]
})
export class CommonGridComponentsModule {}
